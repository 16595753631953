import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import en from '@/plugins/i18n/locales/en';

export default function useEntitiesList() {
    const entityListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Email'), value: 'email', class: 'text-uppercase' },
        { text: i18n.t('Category'), value: 'accessibleLocationCategory.name', class: 'text-uppercase' },
        { text: i18n.t('District'), value: 'location.parent.parent.name', class: 'text-uppercase' },
        { text: i18n.t('County'), value: 'location.parent.name', class: 'text-uppercase' },
        { text: i18n.t('Parish'), value: 'location.name', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const countryFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalEntityListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const entityTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldDistrictFilter = ref(' ');
    const oldCountryFilter = ref(' ');
    const oldCountyFilter = ref(' ');
    const oldParishFilter = ref(' ');

    // fetch data
    const fetchEntityList = () => {
        let verifyQuery = store.getters['app-entity/getEntityQuery'];
        let isDeleteEntity = store.getters['app-entity/getIsDeleteEntity'];
        store
            .dispatch('app-entity/fetchEntityList', {})
            .then((response) => {
                if (isDeleteEntity) {
                    entityListTable.value = [];
                    entityListTable.value.push(...response.data.data);
                    store.commit('app-entity/setIsDeleteEntity', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi &&
                    oldDistrictFilter.value === verifyQuery.filters.location.parent.parent.id.$containsi &&
                    oldCountryFilter.value === verifyQuery.filters.location.parent.parent.parent.id.$containsi &&
                    oldCountyFilter.value === verifyQuery.filters.location.parent.id.$containsi &&
                    oldParishFilter.value === verifyQuery.filters.location.id.$containsi
                ) {
                    entityListTable.value = [];
                    entityListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    oldDistrictFilter.value = verifyQuery.filters.location.parent.parent.id.$containsi;
                    oldCountryFilter.value = verifyQuery.filters.location.parent.parent.parent.id.$containsi;
                    oldCountyFilter.value = verifyQuery.filters.location.parent.id.$containsi;
                    oldParishFilter.value = verifyQuery.filters.location.id.$containsi;
                    entityListTable.value = [];
                    entityListTable.value.push(...response.data.data);
                }
                totalEntityListTable.value = response.data.meta.pagination.total;

                // entityTotalLocal.value = entityTotal;
                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, countryFilter, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchEntityList();
    });

    const resolveEntityPicture = (pictures) => {
        if (_.get(pictures, '[0].formats.thumbnail.url')) return _.get(pictures, '[0].formats.thumbnail.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveEntityPictureMax = (pictures) => {
        if (_.get(pictures, '[0].formats.large.url')) return _.get(pictures, '[0].formats.large.url');

        if (_.get(pictures, '[0].url')) return _.get(pictures, '[0].url');
        return null;
    };
    const resolveEntityRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveEntityRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveEntitiestatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveEntityTotalIcon = (total) => {
        if (total === 'Total Entities') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Entities') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Entities') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Entities') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitEntityText = (entities) => {
        if (entities.length > 1) {
            return ' + ' + (entities.length - 1);
        }
    };

    return {
        entityListTable,
        tableColumns,
        searchQuery,
        countryFilter,
        roleFilter,
        planFilter,
        statusFilter,
        totalEntityListTable,
        loading,
        options,
        entityTotalLocal,
        selectedRows,
        fetchEntityList,
        resolveEntityPicture,
        resolveEntityPictureMax,
        resolveEntityRoleVariant,
        resolveEntityRoleIcon,
        resolveEntitiestatusVariant,
        limitEntityText,
    };
}
