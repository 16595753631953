import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            location: {
                parent: {
                    parent: {
                        parent: {
                            id: {
                                $containsi: '',
                            },
                        },
                        id: {
                            $containsi: '',
                        },
                    },
                    id: {
                        $containsi: '',
                    },
                },
                id: {
                    $containsi: '',
                },
            },
            accessibleLocationCategory: {
                id: {
                    $containsi: '',
                },
            },
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
                {
                    email: {
                        $containsi: '',
                    },
                },

                {
                    location: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
                {
                    location: {
                        parent: {
                            name: {
                                $containsi: '',
                            },
                        },
                    },
                },
                {
                    location: {
                        parent: {
                            parent: {
                                name: {
                                    $containsi: '',
                                },
                            },
                        },
                    },
                },
                {
                    location: {
                        parent: {
                            parent: {
                                parent: {
                                    name: {
                                        $containsi: '',
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    accessibleLocationCategory: {
                        id: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },
        populate: ['pictures', 'location.parent.parent.parent', 'accessibleLocationCategory.marker'],
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        entities: [],
        query: initialQuery(),
        isDeleteEntity: false,
    },
    getters: {
        getEntityList(state) {
            return state.entities;
        },
        getEntityQuery(state) {
            return state.query;
        },
        getEntity(state) {
            return state.entity;
        },
        getIsDeleteEntity(state) {
            return state.isDeleteEntity;
        },
    },
    mutations: {
        setEntityList(state, entities) {
            state.entities = entities;
        },
        setEntityQuery(state, query) {
            state.query = query;
        },
        resetEntityQuery(state) {
            state.query = initialQuery();
        },
        setEntity(state, entityData) {
            state.entityData = entityData;
        },
        setEntitySearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
            state.query.filters.$or[1].email.$containsi = search;
            state.query.filters.$or[2].location.name.$containsi = search;
            state.query.filters.$or[3].location.parent.name.$containsi = search;
            state.query.filters.$or[4].location.parent.parent.name.$containsi = search;
            state.query.filters.$or[5].location.parent.parent.parent.name.$containsi = search;
        },
        setIsDeleteEntity(state, bol) {
            state.isDeleteEntity = bol;
        },
    },
    actions: {
        fetchEntityList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/accessible-locations?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchEntity(ctx, id, query = {}) {
            query.populate = ['pictures', 'location.parent.parent.parent', 'accessibleLocationCategory'];
            query.calculateDistance = true;
            query.latitude = _.get(query, 'latitude', 0);
            query.longitude = _.get(query, 'longitude', 0);

            return new Promise((resolve, reject) => {
                axios
                    .get(`/accessible-locations/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateEntity(ctx, data) {
            let query = qs.stringify({
                populate: ['pictures', 'location.parent.parent.parent', 'accessibleLocationCategory'],
            });
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                _data.pictures = _.get(_data, 'pictures[0].id') ? [_.get(_data, 'pictures[0].id')] : [];
                axios
                    .put(`/accessible-locations/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addEntity(ctx, entityData) {
            entityData = _.assign({}, entityData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/accessible-locations', { data: entityData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteEntity(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/accessible-locations/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyEntities(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/accessible-locations/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
