import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

//accessible locations
export function getEntitiesCategories(query) {
    let queryString = qs.stringify(query);
    return axios.get('/accessible-location-categories?' + queryString);
}
export function importCsv(data, options) {
    let formData = new FormData();
    formData.append('files.files', data);
    formData.append('data', JSON.stringify({ strapi: true }));
    return axios.post('/accessible-location-categories/import-csv', formData, options);
}
